export enum ControlCsvHeaders {
  ID = 'ID',
  Name = 'Name',
  Strategy = 'Strategy',
  Description = 'Description',
  Owner = 'Owner',
  Framework = 'Framework',
  FrameworkID = 'Framework ID',
  Status = 'Status',
  ExpectedLiveDate = 'Expected Live Date (DD-MM-YYYY)',
  ActualLiveDate = 'Actual Live Date (DD-MM-YYYY)',
  ReviewDate = 'Review Date (DD-MM-YYYY)',
  AnnualCost = 'Annual Cost',
  Vendor = 'Vendor',
  Source = 'Source URL',
  UrlName = 'URL Name',

  Created = 'Created',
  Updated = 'Updated',
}

export enum IncidentCsvHeaders {
  ID = 'ID',
  RefID = 'Ref ID',
  Name = 'Name',
  Description = 'Description',
  Source = 'Source URL',
  Date = 'Date of Incident (DD-MM-YYYY)',
  Owner = 'Owner',
  Impact = 'Impact',
  FinancialImpactUpper = 'Financial Impact Upper',
  FinancialImpactLower = 'Financial Impact Lower',
  Type = 'Type (Controlled/Uncontrolled)',
}

export enum TableIds {
  default = 'tid:default',
  scenarioLib = 'tid:scenarioLib',
  addScenarioScenarioLib = 'tid:addScenarioScenarioLib',
  assessmentScenarios = 'tid:assessmentScenarios',
  assessmentScenariosControls = 'tid:assessmentScenariosControls',
  scenarioControls = 'tid:scenarioControls',
  scenarioIncidents = 'tid:scenarioIncidents',
  rmp = 'tid:rmp',
  rmpScenarios = 'tid:rmpScenarios',
  addControlControlLib = 'tid:addControlControlLib',
  controlLib = 'tid:controlLib',
  controlLibFramework = 'tid:controlLibFramework',
  rmpMultiSelectModal = 'tid:rmpMultiSelectModal',
  controlScenarios = 'tid:controlScenarios',
  incidents = 'tid:incidents',
  incidentsScenarios = 'tid:incidentsScenarios',
  incidentSecnarios = 'tid:incidentSecnarios',
  wsMembers = 'tid:wsMembers',
  wsRequests = 'tid:wsRequests',
  wsInvites = 'tid:wsInvites',
  wsPeople = 'tid:wsPeople',
  orgMembers = 'tid:orgMembers',
  prAssessmentScenarios = 'tid:prAssessmentScenarios',
  prScenarioScenarios = 'tid:prScenarioScenarios',
  prAssessmentScenariosControls = 'tid:prAssessmentScenariosControls',
  scenarioLinkExistingControl = 'tid:scenarioLinkExistingControl',
  scenarioLinkExistingIncident = 'tid:scenarioLinkExistingIncident',
  controlLinkScenarioAssessment = 'tid:controlLinkScenarioAssessment',
  controlLinkScenarioScenario = 'tid:controlLinkScenarioScenario',
  incidentLinkScenarioAssessment = 'tid:incidentLinkScenarioAssessment',
  incidentLinkScenarioScenario = 'tid:incidentLinkScenarioScenario',
  auditWorkspace = 'tid:auditWorkspace',
  auditAssessment = 'tid:auditAssessment',
  auditScenario = 'tid:auditScenario',
  auditUser = 'tid:auditUser',
  auditRmp = 'tid:auditRmp',
  auditControl = 'tid:auditControl',
  assessmentVersions = 'tid:assessmentVersions',
  compareAssessments = 'tid:compareAssessments',
  compareAssessmentsV1 = 'tid:compareAssessmentsV1',
  compareAssessmentsV2 = 'tid:compareAssessmentsV2',
  variablesWorkspace = 'tid:variablesWorkspace',
  wizzardOrgModalAddControls = 'tid:wizzardOrgModalAddControls',
}
