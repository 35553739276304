import { AxiosResponse } from 'axios';
import { Modifiers } from './modifiers';
export type ThenArgs<T> = T extends Promise<infer U> ? U : T;
export type AxiosArgs<T> = T extends AxiosResponse<infer U> ? U : undefined;
export type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;

export enum QKeys {
  Assessment = 'assessment',
  Assessments = 'assessments',
  Incidents = 'incidents',
  Incident = 'incident',
  RiskOwners = 'risk_owners',
  Versions = 'versions',
  ControlLibrary = 'control_library',
  ControlLibraries = 'control_libraries',
  ControlLibrariesAll = 'control_libraries_all',
  ControlScenarios = 'control_scenarios',
  Control = 'control',
  Controls = 'controls',
  ScenarioControls = 'scenario_controls',
  ScenarioTemplates = 'scenario_templates',
  Frameworks = 'frameworks',
  FrameworkLibrary = 'framework_library',
  User = 'user',
  OrgUser = 'org_user',
  Scenario = 'scenario',
  Audit = 'audit',
  Organisation = 'organisation',
  Organisations = 'organisations',
  OrganisationAdmin = 'organisation_admin',
  OrganisationUsers = 'organisation_users',
  OrgHierarchy = 'org_hierarchy',
  Workspace = 'workspace',
  WorkspaceUsers = 'workspace_users',
  UserAssociations = 'user_associations',
  DashboardTimeline = 'dashboard_timeline',
  WorkspaceVariables = 'workspace_wariables',
  Wizard = 'wizard',
}

export type Identifier = string | number;

export type ErrorObject = {
  status: number;
  StackTrace: string | null;
  UserMessage: string | null;
};

export enum RiskSources {
  Disgruntled,
  Accidental,
  Ineffective,
  Criminal,
  Coerced,
  Criminals,
  Hacktivists,
  Compromised,
  StateSponsored,
  Competitor,
  Press,
  Researcher,
  Regulator,
}

export enum RiskEvents {
  ServiceUnavailability,
  ServiceCompromise,
  InformationBreach,
  AccessToOurSystemBreach,
  ComplianceFailure,
  RulesChange,
  CriticalVulnerabilityPublished,
  HarmfulSpeech,
  ChildSexualViolentContent,
  Harassment,
  Ransomware,
  Worm,
  Spyware,
  Rootkit,
  Dialler,
  DistributedDenialOfService,
  Sabotage,
  OpenSourceIntelligenceAnalysis,
  NetworkScanning,
  NetworkSniffing,
  Lies,
  Threats,
  Phishing,
  Bribes,
  UnauthorisedAccessToSystemComponent,
  UnauthorisedAccessToInformation,
  UnauthorisedSharingOfInformation,
  UnauthorisedModificationOfInformation,
  UnauthorisedDeletionOfInformation,
  MisappropriationMisuseOfResources,
  FalseRepresentation,
  TheftOfMoney,
  SoftwareExploit,
  SQLInjection,
  CrossSiteScripting,
  FileInclusion,
  ControlSystemBypass,
  UseOfStolenCredentials,
  PasswordBruteForce,
  ProcessFailure,
  AuditFailure,
}

export enum RiskConsequencesLevels {
  Operations,
  Compliance,
  Financial,
  Strategic,
}

export enum RiskConsequences {
  ReducedGrowth,
  BusinessDisruption,
  IneffectiveChange,
  SlowRecovery,
  ReducedAccessToStaffSkills,
  LossOfSuppliers,
  EnvironmentalHarm,
  SafetyFailure,
  SocialHarm,
  MedicalHarm,
  NonCompliance,
  PoorConductIntegrity,
  DamagedRegulatorRelations,
  RegulatoryFines,
  LegalChallenge,
  TheftOfMoney,
  UnplannedCosts,
  IncreasedCostsInefficiency,
  DamagedReputation,
  EmbarrassingReporting,
  DamagedInvestorRelations,
}

export enum IncidentType {
  Controlled = 'Controlled',
  Uncontrolled = 'Uncontrolled',
}

export interface OrganisationWorkspace {
  id: string;
  name: string;
  primary_contact: string;
  billing_contact: string | null;
  domain: string;
  parent_id: string | null;
  plan: Plan;
  admin_id: number;
  active: boolean;
  is_restricted: boolean;
  users: {
    id: number;
    is_admin: boolean;
    email: string;
    name: string;
    organisation_name: string;
    profile_photo_path: string | null;
    member: {
      id: string;
      accepted_date: string | null;
      created_at: string | null;
      invited_date: string | null;
      requested_date: string | null;
      updated_at: string | null;
    };
  }[];
  requests: {
    id: number;
    email: string;
    name: string;
    is_admin: boolean;
    organisation_name: string;
    profile_photo_path: string | null;
    request: {
      id: string;
      accepted_date: string | null;
      created_at: string;
      invited_date: string | null;
      requested_date: string;
      updated_at: string | null;
    };
  }[];
  invites: {
    id: number;
    email: string;
    name: string;
    is_admin: boolean;
    organisation_name: string;
    profile_photo_path: string | null;
    invite: {
      id: string;
      accepted_date: string | null;
      created_at: string;
      invited_date: string | null;
      requested_date: string;
      updated_at: string | null;
    };
  }[];
  type: 'O' | 'W';
  permissions: {
    'app.organisation.create_as_child': '0' | '1';
    'app.organisation.create_workspace': '0' | '1';
    'app.organisation.manage_users': '0' | '1';
    'app.organisation.edit_users': '0' | '1';
  };
}

export interface User {
  id: number;
  auth0_id: string;
  created_at: string;
  is_admin: boolean;
  email: string;
  name: string;
  workspace_user_name: string;
  workspace_name: string;
  workspace_id: string;
  max_assessments: number;
  updated_at: string;
  profile_photo_path: string | null;
  permissions: { [key: string]: '0' | '1' } | null;
  features: { [key: string]: '0' | '1' } | null;
  organisations: OrganisationWorkspace[];
  workspaces: OrganisationWorkspace[];
  member?: {
    accepted_date: string | null;
    created_at: string | null;
    invited_date: string | null;
    organisation_id: number | null;
    requested_date: string | null;
    updated_at: string | null;
    user_id: number;
    ux_id: string;
  };
  requests: {
    active: null | number | boolean; //TODO wrong type
    admin_id: number;
    is_restricted: boolean;
    billing_contact: string | null;
    domain: string;
    id: string;
    name: string;
    parent_id: number | null;
    plan: Plan;
    primary_contact: string;
  }[];
  invites: {
    id: string;
    admin_id: number;
    is_restricted: boolean;
    billing_contact: string | null;
    domain: string;
    name: string;
    parent_id: number | null;
    plan: Plan;
    primary_contact: string;
    invite: {
      id: string;
      accepted_date: string | null;
      created_at: string;
      invited_date: string | null;
      requested_date: string;
      updated_at: string | null;
    };
  }[];
  terms: {
    can_defer: 'yes' | 'no' | null;
    defer_expires: string | null;
    status:
      | 'NO_ORGANISATION'
      | 'NOT_AUTHORISED_TO_ACCEPT'
      | 'REQUIRED'
      | 'NO_TERMS'
      | 'ACCEPTED'
      | 'DEFERRED'
      | 'AUTHORISED_TO_BYPASS';
    terms_id: string | null;
    terms_url: string | null;
    organisation_id: string | null;
    organisation_name: string | null;
  };
}

export interface UserOrgProfile {
  accepted_date: string | null;
  email: string;
  is_admin: boolean;
  name: string;
  profile_photo_path: string | null;
}

export interface Assessment {
  id: string;
  name: string;
  description: string;
  review_date: string;
  scenarios_count: number;
  expected_loss: number;
  chart: Array<{ x: number; y: number }>;
  created_at: string;
  updated_at: string;
  risk_tolerance: string;
  variables: Variable[];
  scenarios: Scenario[];
  executive_summary: string;
  started_date: string | null;
  meta?: {
    created_at: string;
    created_by: string;
    created_by_id: number;
    updated_at: string;
    updated_by: string;
    updated_by_id: number;
  };
  legacy_version: boolean;
}

export interface AssessmentVersion {
  id: string;
  editable: 0 | 1;
  name: string;
  userName: string;
  profilePhotoPath: string | null;
  created_at: string;
}

export enum Strategy {
  Treat = 'Treat',
  Transfer = 'Transfer',
  Tolerate = 'Tolerate',
  Terminate = 'Terminate',
}

export enum ControllStatus {
  NotStarted = 'Not Started',
  InProgress = 'In Progress',
  Live = 'Live',
  Abandoned = 'Abandoned',
}

export interface Scenario {
  id: string;
  assessment_id: string;
  assessment_name: string;
  reference_id: string;
  updated_by_id: number;
  name: string;
  risk_owner: UserOptionType | null;
  risk_narrative: string;
  frequency_times: number;
  frequency_years: number;
  frequency_note: string;
  expected_loss: number;
  upper: number;
  lower: number;
  upper_formula: string;
  lower_formula: string;
  note: string;
  event: RiskEvents;
  source: RiskSources;
  consequence: RiskConsequences;
  chart?: { x: number; y: number }[];
  created_at: string;
  updated_at: string;
  management_note: string | null;
  management_strategy: Strategy | null;
  is_incomplete: number;
  incidents: Incident[];
  risk_identified_date: string | null;
  review_date: string | null;
  implementationPlans: {
    control: Control;
    modifiers: any[];
  }[];
  lower_than_minimum?: string;
  legacy_version: boolean;
}

export interface PlanScenarios {
  id: string;
  control_id: string;
  name: string | null;
  controlName: string;
  scenarioName: string;
  scenario_id: string;
  scenario_data: Scenario;
  control_data: Control;
}

export interface ProjectedScenario {
  id: string;
  upper: number;
  lower: number;
  frequency_times: number;
  frequency_years: number;
}

export interface ModScenario extends Omit<Scenario, 'event' | 'source' | 'consequence'> {
  source: string;
  event: string;
  consequence: string;
}

export interface ScenarioTemplate extends Scenario {
  ux_id: string;
  author: string;
  description: string;
}

export interface AuditAction {
  field_name: string;
  before: string;
  after: string;
}

export interface AuditItem {
  audit_id: number;
  user_name: string;
  profile_photo_path: string | null;
  name: string;
  action_title: string;
  actions: AuditAction[] | null;
  date_time: string;
}

export interface Variable {
  id: number;
  name: string;
  value: number;
  currency: boolean;
  isInUse: string[];
  workspace_variable: boolean;
  alias: string | null;
  isGlobal: boolean;
}

export interface VariablePost extends Omit<Variable, 'value' | 'id'> {
  id?: number;
  value: number | null;
}

export interface Incident {
  id: string;
  refId: string;
  name: string;
  description: null | string;
  source: null | string;
  date: null | string;
  owner: UserOptionType | null;
  impact: null | string;
  financialImpactUpper: null | number;
  financialImpactLower: null | number;
  type: IncidentType | null;
  scenarios: Scenario[];
  scenariosCount: number;
}

export interface Framework {
  ux_id: string;
  name: string;
  shortname: string | null;
  frameworkLibraryId: string | null;
  external_id: string | null;
  version: string | null;
  author: string | null;
  description: string | null;
  publish_date: string | null;
  control_libraries_count: number;
  user_id: number;
  deleted_at: string | null;
  created_at: string;
  updated_at: string;
}

export interface ControlLibrary {
  ux_id: string;
  name: string;
  shortname: string | null;
  controlLibraryId: string | null;
  description: string | null;
  strategy: Strategy | null;
  framework_id: number | null;
  framework_ux_id: string | null;
  user_id: number;
  deleted_at: string | null;
  created_at: string;
  updated_at: string;
  framework_library: Framework;
}

export interface ControlLibraryShort {
  ux_id: string;
  name: string;
  shortname: string | null;
  controlLibraryId: string | null;
  description: string | null;
  strategy: Strategy | null;
  framework_id: number | null;
  framework_ux_id: string | null;
  status: ControllStatus | null;
  user_id: number;
  created_at: string;
  updated_at: string;
  frameworkLibraryName: string | null;
  frameworkLibraryId: string | null;
  frameworkShortname: string | null;
  FrameworkAuthor: string | null;
}

export interface Control {
  id: string;
  name: string;
  shortname: string | null;
  controlId: string | null;
  description: string | null;
  strategy: Strategy | null;
  status: ControllStatus | null;
  expected_active_date: string | null;
  review_date: string | null;
  annual_cost: number | null;
  vendor: string | null;
  origin: string;
  actual_active_date: string | null;
  controlLibrary_Id: string | null;
  controlLibrary_name: string | null;
  frameworkLibraryId: string | null;
  frameworkLibrary_name: string | null;
  frameworkLibrary_shortname: string | null;
  frameworkLibrary_ux_id: string | null;
  created_at: string;
  updated_at: string;
  user_id: number;
  owner: UserOptionType | null;
  modifiers?: Modifiers; //TODO ???
  scenarios?: Scenario[];
  source: string | null;
  url_name: string | null;
}

export enum Plan {
  Trial = 'Trial',
  Paid = 'Paid',
}

export enum WorkspaceType {
  Trial = 'Trial',
  Paid = 'Paid',
}

export enum UserRole {
  Admin = 'Admin',
  Collaborator = 'Collaborator',
}

export interface PdfScenario extends Omit<Scenario, 'incidents'> {
  controls: Control[];
  chartImage: string;
  incidents: Incident[];
}
export interface PdfAssessment extends Omit<Assessment, 'scenarios'> {
  scenarios: PdfScenario[];
}

export interface OrgTreeItem {
  id: string;
  name: string;
  domain: string;
  type: 'O' | 'W';
  primary_contact: string;
  billing_contact?: string;
  children?: OrgTreeItem[];
  imgUrl?: string | null;
}

export interface OrgUser {
  id: number;
  name: string;
  workspace_user_name: string;
  email: string;
  profile_photo_path: string | null;
  permissions: {
    'app.organisation.create_as_child': '0' | '1';
    'app.organisation.create_workspace': '0' | '1';
    'app.organisation.manage_users': '0' | '1';
    'app.organisation.edit_users': '0' | '1';
  };
  member: {
    role: string;
    aka: string | null;
  };
}

export interface WorkspaceUser extends OrgUser {
  member: {
    id: string;
    role: string;
    aka: string | null;
    accepted_date: string;
  };
}

export interface UserAssociation {
  id: string;
  name: string;
  type: 'O' | 'W';
  children?: UserAssociation[];
}

export enum SortType {
  ByCreatedDate = 'meta.created_at',
  ByReviewDate = 'review_date',
  ByExpectedLoss = 'expected_loss',
  ByNumberOfScenarios = 'scenarios_count',
  ByName = 'name',
  UpdatedAt = 'meta.updated_at',
}

export interface UserOptionType {
  id?: string;
  text: string;
  email?: string | null;
  profile_photo_path?: string | null;
  invite_id?: string | null;
  member_id?: string | null;
}

export enum ProjectionVariant {
  base = 'base',
  live = 'live',
  complete = 'complete',
}
