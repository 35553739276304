import React, { useState } from 'react';
import { QKeys, Scenario } from 'src/api/types';
import { GradientText, NoData, Spacer } from 'src/common';
import { queryCache, useMutation } from 'react-query';
import { unlinkIncident } from 'src/api/incidents';
import styled from 'styled-components';
import Button from 'src/components/form/Button';
import { LinkIncidentsToScenarioModal } from 'src/components/modals';
import GenericTable, { TableSheetIncident } from 'src/components/GenericTable';
import { useColumnsIncidents } from './useColumnsIncidents';
import useVersion from 'src/hooks/useVersion';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { TableIds } from 'src/api/types/misc';

const Div = styled.div`
  .head {
    display: flex;
    justify-content: space-between;
  }
`;

interface IncidentsProps {
  scenario: Scenario;
}

export const Incidents: React.FC<IncidentsProps> = ({ scenario }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { version } = useVersion();

  const [unlink] = useMutation(unlinkIncident, {
    onSuccess: () => {
      queryCache.invalidateQueries([QKeys.Incidents]);
      queryCache.invalidateQueries([QKeys.Scenario, { scenarioId: scenario.id, variant: 'base' }]);
      queryCache.invalidateQueries([QKeys.Scenario, { scenarioId: scenario.id }]);
    },
  });

  const { columns, GTColumnSelectAnchorExported } = useColumnsIncidents({
    data: scenario.incidents,
    tdm: {
      onUnlinkIncidentClick: (incidentId) =>
        unlink({
          incidentId,
          scenarioId: scenario.id,
        }),
    },
  });

  return (
    <>
      {isModalOpen && (
        <LinkIncidentsToScenarioModal
          onClose={() => setIsModalOpen(false)}
          scenarioId={scenario.id}
          subTitle={{
            title: 'SCENARIO',
            value: scenario.name,
          }}
        />
      )}
      <Div>
        <div className="head">
          <GradientText>INCIDENTS</GradientText>
          {!version && (
            <Button
              primary
              onClick={() => {
                setIsModalOpen(true);
                mpEvent(MPEvents.ButtonClick, {
                  button: 'Add incidents',
                  tags: ['SCENARIO', 'INCIDENT'],
                });
              }}
              data-cy="add-incidents-btn"
              $constWidth={200}
            >
              + ADD Incidents
            </Button>
          )}
        </div>

        {scenario.incidents.length ? (
          <>
            <Spacer $px={20} />
            <GenericTable
              tableId={TableIds.scenarioIncidents}
              data={scenario.incidents}
              columns={columns}
              GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
              expandContent={(data) => <TableSheetIncident data={data} />}
              searchable={['refId', 'name', 'type', 'date']}
            />
          </>
        ) : (
          <>
            <Spacer $px={5} />
            <NoData title="No incidents linked" />
          </>
        )}
      </Div>
    </>
  );
};